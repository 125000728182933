import '../src/assets/css/style.css';
import './assets/css/custom.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import IsLogIn from './pages/IsLogIn';
import IsLogOut from './pages/IsLogOut';

import LogIn from './pages/Login';
import Home from './pages/Home';

 // ---------------------------------------------------------- 

import ViewInfo from './pages/info/ViewInfo';
// import AddInfo from './pages/info/AddInfo';
import EditInfo from './pages/info/EditInfo';

import AddUser from './pages/user/AddUser';
import AllUser from './pages/user/AllUser';

import AllMessage from './pages/message/AllMessage';

import AddSlider from './pages/slider/AddSlider';
import AllSlider from './pages/slider/AllSlider';


import AddCategory from './pages/category/AddCategory';
import AllCategory from './pages/category/AllCategory';
import EditCategory from './pages/category/EditCategory';

import AddPackage from './pages/package/AddPackage';
import AllPackage from './pages/package/AllPackage';
import EditPackage from './pages/package/EditPackage';
import ViewPackage from './pages/package/ViewPackage';
import AddDetails from './pages/package/AddDetails';


// ----------------------------------------------------------

// import AddService from './pages/service/AddService';
// import AllService from './pages/service/AllService';
// import EditService from './pages/service/EditService';
// import ViewService from './pages/service/ViewService';

// import AddInclude from './pages/service/Include/AddInclude';
// import AddExclude from './pages/service/exclude/AddExclude';
// import AddFaq from './pages/service/faq/AddFaq';
// import AddDetails from './pages/service/AddDetails';


// import AddSubService from './pages/subService/AddSubService';
// import AllSubService from './pages/subService/AllSubService';
// import EditSubService from './pages/subService/EditSubService';

// import AddPackage from './pages/package/AddPackage';
// import AllPackage from './pages/package/AllPackage';
// import EditPackage from './pages/package/EditPackage';
// import ViewPackage from './pages/package/ViewPackage';
// import AddPacDetails from './pages/package/AddDetails';

// import AddUnit from './pages/unit/AddUnit';
// import AllUnit from './pages/unit/AllUnit';
// import EditUnit from './pages/unit/EditUnit';

// import AddProduct from './pages/product/AddProduct';
// import AllProduct from './pages/product/AllProduct';
// import EditProduct from './pages/product/EditProduct';

// import AllWorker from './pages/worker/AllWorker';
// import AddWorker from './pages/worker/AddWorker';
// import EditWorker from './pages/worker/EditWorker';
// import ViewWorker from './pages/worker/ViewWorker';
// import WorkTodo from './pages/worker/WorkTodo';

// import AddCustomer from './pages/customer/AddCustomer';
// import AllCustomer from './pages/customer/AllCustomer';
// import EditCustomer from './pages/customer/EditCustomer';
// import Editor from './components/Editor';


// import AllSale from './pages/sale/AllSale';
// import EditSale from './pages/sale/EditSale';
// import ViewSale from './pages/sale/ViewSale';
// import Invoice from './pages/sale/Invoice';

// import WorkTodoReport from './pages/report/WorkTodo';
// import RequestService from './pages/report/RequestService';




function App() {
  return (
    <Routes>
      
      <Route path='/' element={<Navigate to='/dashboard' />} />

      {/* <Route path='/sale/invoice/:id' element={<Invoice />} /> */}

      <Route path='/*' element={<IsLogOut />} >
        <Route path="logIn" element={ <LogIn />} />
      </Route>

      <Route path='/' element={<IsLogIn />} >
        <Route path='/' element={<Layout />} >
          <Route path='dashboard' element={<Home />} />

          {/* ---------------------------------------------------------- */}

          <Route path='info' element={<ViewInfo />} />
          {/* <Route path='addInfo' element={<AddInfo />} /> */}
          <Route path='editInfo' element={<EditInfo />} />

          
          <Route path='addUser' element={<AddUser />} />
          <Route path='users' element={<AllUser />} />

          <Route path='messages' element={<AllMessage />} />

          <Route path='addSlider' element={<AddSlider />} />
          <Route path='sliders' element={<AllSlider />} />


          <Route path='addCategory' element={<AddCategory />} />
          <Route path='allCategory' element={<AllCategory />} />
          <Route path='editCategory/:id' element={<EditCategory />} />


          <Route path='addPackage' element={<AddPackage />} />
          <Route path='allPackage' element={<AllPackage />} />
          <Route path='editPackage/:id' element={<EditPackage />} />
          <Route path='package/:id' element={<ViewPackage />} />
          <Route path='addDetails/:id' element={<AddDetails />} />




           {/* ---------------------------------------------------------- */}

          {/* <Route path='addService' element={<AddService />} />
          <Route path='allService' element={<AllService />} />
          <Route path='editService/:id' element={<EditService />} />
          <Route path='viewService/:id' element={<ViewService />} />

          <Route path='addInclude/:id' element={<AddInclude />} />
          <Route path='addExclude/:id' element={<AddExclude />} />
          <Route path='addFaq/:id' element={<AddFaq />} />
          <Route path='service/addDetails/:id' element={<AddDetails />} />


          <Route path='addSubService' element={<AddSubService />} />
          <Route path='allSubService' element={<AllSubService />} />
          <Route path='editSubService/:id' element={<EditSubService />} />

          <Route path='addPackage' element={<AddPackage />} />
          <Route path='allPackage' element={<AllPackage />} />
          <Route path='editPackage/:id' element={<EditPackage />} />
          <Route path='viewPackage/:id' element={<ViewPackage />} />
          <Route path='package/addDetails/:id' element={<AddPacDetails />} />

          <Route path='addUnit' element={<AddUnit />} />
          <Route path='allUnit' element={<AllUnit />} />
          <Route path='editUnit/:id' element={<EditUnit />} />

          <Route path='addProduct' element={<AddProduct />} />
          <Route path='allProduct' element={<AllProduct />} />
          <Route path='editProduct/:id' element={<EditProduct />} />

          <Route path='allWorkers' element={<AllWorker />} />
          <Route path='addWorker' element={<AddWorker />} />
          <Route path='editWorker/:id' element={<EditWorker />} />
          <Route path='worker/:id' element={<ViewWorker />} />
          <Route path='workTodo/:id' element={<WorkTodo />} />

          <Route path='allCustomer' element={<AllCustomer />} />
          <Route path='addCustomer' element={<AddCustomer />} />
          <Route path='editCustomer/:id' element={<EditCustomer />} />

          <Route path='allSale' element={<AllSale />} />
          <Route path='editSale/:id' element={<EditSale />} />
          <Route path='sale/:id' element={<ViewSale />} />


          <Route path='allTodo' element={<WorkTodoReport />} />
          <Route path='allServiceRequest' element={<RequestService />} />

          <Route path='editor' element={<Editor />} /> */}
        </Route>
      </Route>

    </Routes>
  );
}

export default App;
