import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditServiceGroup() {
  const [value, setValue] = useState({phone1:'', phone2:'', email:'', address:'', about:'', file:{}});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  // function icon(e){
  //   setValue({...value,[e.target.name]:e.target.files[0]});
  // }

  function send(){

    const formData = new FormData();

    formData.append('phone1',value.phone1);
    formData.append('phone2',value.phone2);
    formData.append('email',value.email);
    formData.append('address',value.address);
    formData.append('about',value.about);
    // formData.append('photo',value.file);

    fetch(`${url}/info/`,{
      method:"PUT",
      body:  formData,
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }

  useEffect(()=>{

    fetch(`${url}/info`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        setValue(data.data[0]);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });

  },[]);




  return (
    <Form1 title="Edit Info">
        <div className= " fixed top-16 right-2 ">
          <AlertContainer>
              {alert}
          </AlertContainer>
        </div>
        <Input1 onChange={set} name="phone1" placeholder="Enter phone 1:" value={value.phone1} lavel="Phone 1:" />
        <Input1 onChange={set} name="phone2" placeholder="Enter phone 2:" value={value.phone2} lavel="Phone 2:" />
        <Input1 onChange={set} name="email" placeholder="Enter email:" value={value.email} lavel="email:" />
        <Input1 onChange={set} name="address" placeholder="Enter address:" value={value.address} lavel="address:" />
        <Textarea onChange={set} name="about" placeholder="Enter about us description:" value={value.about} lavel="About us description:" />
        {/* <Input1 onChange={(e)=>icon(e)} type="file" name="file" lavel="Upload an icon." /> */}
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditServiceGroup;