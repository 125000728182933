import React, { useState }from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import SideBarToggler from './SideBarToggler';
import MinSideBarToggler from './MinSideBarToggler';

function Sidebar({bar, mbar}) {

    const [active, setActive] = useState(-1);
    console.log('sidebar')

    const data = [

        {
            title:'Category',
            icon: <i className="fa-solid fa-table-cells pr-2"></i>,
            links:[
                {name:' All Category',link:'/allCategory'},
                {name:'Add Category',link:'/addCategory'},
            ]
        },
        {
            title:'Package',
            icon: <i className="fa-solid fa-cubes"></i>,
            links:[
                {name:' All Package',link:'/allPackage'},
                {name:'Add Package',link:'/addPackage'},
            ]
        },
        {
            title:'Settings',
            icon: <i className="fa fas fa-cog pr-2"></i>,
            links:[
                {name:'Info',link:'/info'},
                {name:'Slider',link:'/sliders'},
            ]
        },
    ]
  return (
      <>
        {
          (mbar === false)?
          <div className={`${bar} hidden  md:w-1/4 lg:w-1/5 xl:w-2/12 float-left transition-all duration-700 md:ml-0 h-screen  md:flex flex-col `}>
            <Link to='/'><div className=' py-4 h-screen-2 bg-dark-blue-2 text-center text-2xl text-white overflow-hidden'>Holy Review</div></Link>
            <div className=' h-full bg-slate-50'>
                <NavLink to='/users' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-user-group"></i> Users </NavLink>
                <NavLink to='/messages' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-envelope"></i> Messages </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <SideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
       :
       <div className=' w-[calc(0%+56px)] float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>HR</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/users' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-user-group"></i> </NavLink>
                <NavLink to='/messages' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-envelope -ml-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
   } 

        <div className=' w-[calc(0%+56px)] md:hidden float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>HR</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/users' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-user-group"></i> </NavLink>
                <NavLink to='/messages' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-envelope -ml-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
      </>



  )
}

export default Sidebar