import React, {  useEffect, useState } from 'react';
import { Delete, Edit } from '../../components/Button';
import Filter from '../../components/Filter';
import Table, { Tr, Td } from '../../components/Table';
import { Option, Select } from '../../components/Input';
import Loader from '../../components/Loader';
import url from '../../url';
import { View } from '../../components/Button';

function AllPackage() {

const [packag, setPackage] = useState({data:[],next:{},previous:{}});
// const [serviceGroup, setServiceGroup] = useState([]);
// const [selectSG, setSelectSG] = useState("");
// const [service, setService] = useState([]);
// const [selectS, setSelectS] = useState("");
const [subService, setSubService] = useState([]);
const [src, setSrc] = useState('');
const [page, setPage] = useState(1);
const [limit, setLimit] = useState(3);
const [loader, setLoader] = useState(true);



function delet(id){
    fetch(`${url}/package/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/package?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setPackage(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setPackage(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}

// const [subService, setSubService] = useState([]);
// const navigate = useNavigate();
// const {id} = useParams();

// useEffect(()=>{

//     fetch(`${url}/serviceGroup?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//         if(data.status === true){
//             setServiceGroup(data.result.data);
//         }
//     });

// },[]);

// useEffect(()=>{

//     setLoader(true);
//     fetch(`${url}/service/search?page=1&limit=0&search=${selectSG}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//         setLoader(false);
//         if(data.status === true){
//             setService(data.result.data);
//         }else{
//             setService([])
//         }
//     });
// },[selectSG]);


// useEffect(()=>{
//     setLoader(true);
//     fetch(`${url}/category/search?page=${1}&limit=${0}&search=${selectS}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//         setLoader(false);
//         if(data.status === true){
//             setSubService(data.result.data);
//         }else{
//             setSubService([])
//         }
//     });
// },[selectS]);





useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/package?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setPackage(data.result);
            }
        });
    }

    return () => setPackage({data:[],next:{},previous:{}});

},[page,src, limit]);


useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
            setSubService(data.result.data)
        }
    });

},[]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setPackage(data.result);
            }
        });
    
    }

    return () => setPackage({data:[],next:{},previous:{}});
},[page, limit, src]);



function search(e){
    setSrc(e.target.value);
}


function byCat(cat){
    setLoader(true);
    fetch(`${url}/package/search?page=${page}&limit=${limit}&search=${cat}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setPackage(data.result);
        }else{
            setPackage({data:[],next:{},previous:{}})
        }
    });
}



  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <div>
        <Filter >

            <div className=' w-80 '>
                <Select onChange={(e)=>byCat(e.target.value)}  name="serviceId"  id="input4"  lavel="Select sub Service :">
                <Option value="" disable={true}>Select a sub service</Option>
                {
                    subService.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

        </Filter>

        <Table to="/addPackage" name="Add Package" rowNames={["#","Name", "Details","Category","Image","date","Actions"]} page={setPage} limit={limit} setLimit={setLimit} srcVal={src} srcFunc={search} data={packag} width='w-full'>
                {
                    packag.data.map(({_id, name, image, category, date}, index)=>{
                        const d = new Date(date);
                        return(
                            <Tr key={index}>
                                <Td>{((packag.previous.page*packag.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td width='w-40'><View to={"/package/"+_id} /></Td>
                                <Td>{category.name}</Td>
                                <Td width='w-40'><img className=' w-20 text-center block mx-auto' src={image.img} alt="" /></Td>
                                <Td>{d.toLocaleString()}</Td>
                                <Td>
                                    <Edit to={"/editPackage/"+_id} />
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
    </div>

}</>)
}


// useEffect(()=>{

//     fetch(`${url}/package/${id}`,{
//         method:"GET",
//         credentials: 'include'
//     }).then((data)=>data.json()).then((data)=>{
//         if(data.status === true){
//           setValue({name: data.data.name, description: data.data.description, subServiceId: data.data.subService._id, unitId: data.data.unit._id, purchasePrice: data.data.purchasePrice, price: data.data.price, discountPrice:data.data.discountPrice, quantity: data.data.quantity});
//         }else{
//           setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
//         }
//     });

//   },[id]);


//   useEffect(()=>{

//     fetch(`${url}/subService?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//         if(data.status === true){;
//           setSubService(data.result.data)
//         }else{
//           setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
//         }
//     });

//     fetch(`${url}/unit?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//       if(data.status === true){;
//         setUnit(data.result.data)
//       }else{
//         setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
//       }
//   });

//   },[]);

export default AllPackage;