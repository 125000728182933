import React from 'react';
function Home() {




  return (
    <div className="w-full h-auto p-4 pb-20 flex gap-4 flex-wrap">

    </div>
  )
}

export default Home;