import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1 } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddCategory() {
  const [value, setValue] = useState({name:'', email:'',phone:'',password:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/user`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Add User">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter category name:" value={value.name} lavel="Category name:" />
        <Input1 onChange={set} type="email" name="email" placeholder="Enter category email:" value={value.email} lavel="Category email:" />
        <Input1 onChange={set} name="phone" placeholder="Enter category phone:" value={value.phone} lavel="Category phone:" />
        <Input1 onChange={set} type="password" name="password" placeholder="Enter category password:" value={value.password} lavel="Category password:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddCategory