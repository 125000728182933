import React, {  useEffect, useState } from 'react';
import url from '../../url'
import { NavLink } from 'react-router-dom';

function AllServiceGroup() {

const [info, setInfo] = useState({});;

useEffect(()=>{
        fetch(`${url}/info`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setInfo(data.data[0]);
            }
        });


    return () => setInfo({});
},[]);



  return (<>
    <div className=' mt-10'>
        <NavLink to="/editInfo" ><button className=' my-1 px-2 bg-green-700 text-lg font-bold text-white rounded-full block mx-auto'>Edit</button></NavLink>
        <div className=' w-[380px] md:w-[700px] h-auto mt-5  bg-slate-100 shadow-md rounded-md mx-auto p-2 md:p-6'>
            <div><span className=' font-bold'>Phone 1: </span><span>{info.phone1}</span></div>
            <div><span className=' font-bold'>Phone 2: </span><span>{info.phone2}</span></div>
            <div><span className=' font-bold'>Email: </span><span>{info.email}</span></div>
            <div><span className=' font-bold'>Address: </span><span>{info.address}</span></div>
            <div>
                <span className=' font-bold'>About Us: </span>
                <p className=' text-justify'>{info.about}</p>
            </div>
        </div>
    </div>
</>)
}

export default AllServiceGroup;