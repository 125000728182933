import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Option, Select } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddService() {
  const [value, setValue] = useState({name:'', description:'', serviceGroupId:'', image:{}});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [alert, setAlert] = useState([]);
// const [subService, setSubService] = useState([]);
const navigate = useNavigate();
const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){
    
    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('categoryId',value.serviceGroupId);
    formData.append('image',value.image);

      fetch(`${url}/package/${id}`,{
        method:"PUT",
          body:  formData,
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
      });

    }


    useEffect(()=>{

          fetch(`${url}/package/${id}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
              if(data.status === true){
                setValue({name:data.data.name, serviceGroupId:data.data.category});
              }
          });

    },[]);

  useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
          setServiceGroup(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
        }
    });

  },[]);


  return (
    <Form1 title="Edit Package">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter service name:" value={value.name} lavel="Package name:" />
        {/* <Textarea onChange={set} name="description" placeholder="Enter service description:" value={value.description} lavel="Service description:" /> */}
        <Select onChange={set}  name="serviceGroupId" value={value.serviceGroupId}  id="input4"  lavel="Select Category :">
          <Option value="" disable={true}>Select a Category</Option>
          {
              serviceGroup.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" lavel="Upload an image." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddService;